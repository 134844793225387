.left-sec-Img{
    width: 100%;
    height: 550px;
    margin: 50px 30px 50px 30px;
}

.current-container-style{
    margin: 50px 30px 50px 30px
}
.kyc-section1-bgd{
    background-color: #981d1f;
/*    margin-top: 15%;
    padding: 10px;*/
}
.kyc-section2-bgd{
    background-color: #981d1f;
   /* margin-top: 20px;*/
}
.right-sec-Img{
    width: 100%;
    height: 480px;
    margin: 30px 50px 30px 50px;
}

.mb-2-h3-font{
    color: #ffffff;
    font-weight: bold;
}

.mb-2-h3-font-kyc{
    color: #ffffff;
    font-weight: bold;
    margin-top: 80px;
}

.inner-sec-txt{
    color: #ffffff;
    display: flex;
}
.color--primary{
    color: #981d1f; 
    font-weight: bold;
}
.mb-3-head{
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}
.mb-4-para{
    color: #7C7D8D;
    justify-content: center;
    text-align: center;
}
.fr-section{
    padding: 10px;
    min-height: 410px;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
    -webkit-box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
}

.h5-font-mb{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.four-wrapper{
    background-color: #ffffff;  
    padding-bottom: 65px;
    padding-top: 50px;
}

.home-img-style{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.h5-font{
    font-size: 18px;
    font-weight: bold;
}

.txt-fr-para{
    color: #7C7D8D;
    text-align: center;
}


@media (max-width: 768px) {
    .kyc-section1-bgd{
        background-color: #981d1f;
        margin-top:0px;
        padding: 0px;
    }
    .left-sec-Img {
        width: 80%;
        height: 200px;
        margin: 50px 30px 50px 30px;
    } 
    
    
    .current-container-style{
        margin: 10px 20px 10px 20px;
    }

    .mb-2-h3-font{
        font-size: 20px;
    }

    .opacity-txt-para {
        color: #ffffff;
        font-size: 1.0625rem;
        line-height: 1.75;
    }
    .mb-2-h3-font-kyc{
     font-size: 20px;
    }

    .right-sec-Img {
        width: 95%;
        height: 360px;
        margin: 0;
    }
}

.opacity-txt-para{
    color: #ffffffb0 !important;
}


.opacity--05 {
    opacity: .05;
}
.z-index1 {
    z-index: 1;
}
.pos-abs-lg-vertical-center {
    position: relative !important;
    top: 20%;
    -webkit-transform: translate(0, -20%);
    -ms-transform: translate(0, -20%);
    transform: translate(0, -20%);
}
.dec-img{
    position: relative;
    margin: 0 auto;
    padding: 0;
    top: 0px;
    left: 85px;
    width: 60%;
}
.red-circle{
    background: #f5e8e9;
    color: #981c1f;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    font-size: 40px;
    margin: 0 auto;
    padding-top: 17px;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 25px;
}
.next-gen p{
    padding: 0 65px;
}
.next-gen{
    background: #F5F9FF;
    padding-top: 4.5rem !important;
    padding-bottom: 2.5rem !important;
}
.svg-shape--top {
    position: relative !important;
    top: -2px;
    right: auto;
    bottom: auto;
    left: 0px;
}
.theme-royal-blue .fill-primary-light--1 path{
    fill: #F5F9FF;
}