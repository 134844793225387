.Footer-wrapper-container{
    background-color: #9b2426;
}

.Footer-container{
  padding-top: 30px;
}

.logo-img-style{
/*    width: 137px;
    height: 88px;*/
}
.Footer-wrapper-container p{
color: #ffffffb0 !important;
}
.logo-sub-txt{
    color: #ffffffb0 !important;
}

.Footer-wrapper-container ul li {
    list-style: none;
    color: #ffffffb0 !important;
    margin-bottom: 7px;
}
.footer-sub{
        color: #ffffffb0 !important;
    font-size: 14px;
}
.footer-head{
color: #ffffff !important;
margin-left: 0;
font-size: 1.25rem!important;
font-weight: bold !important;
}

.logo-sub-para{
    color: #ffffff;
    margin-top: 20px;
}

.footer-social-flex{
    margin-top: 20px;
    margin-bottom: 20px;
        text-align: right;
}

.social-img-footer{
    width: 16px;
    height:18px;
    margin-left: 20px;
}
.bottom-footer{
    margin-top: 20px;
    border-top: 0.5px solid #a7a7a733;
}