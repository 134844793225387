body {
  font-family: 'SFUI', sans-serif;
  overflow-x: hidden;
  width: 100%;
  color: #12141f;
  background-color: #fff !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  font-family: 'SFUI', sans-serif;
  letter-spacing: -0.7px;
}

p {
  font-size: 15px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  font-family: 'SFUI', sans-serif !important;
  color: #7c7d8d !important;
}
a,
.btn,
button {
  border-radius: 0.3125rem !important;
}
a .btn {
  border: 0;
  outline: 0;
  font-weight: 700;
  padding: 1.125rem 2.25rem;
  font-size: 1.0625rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-size: 110% 110% !important;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.125rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.navbar {
  z-index: 1 !important;
}
.innerpage-box {
  background-color: #fff;
  padding: 20px 30px;
  margin-top: 0;
  box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
}
.dash-red {
  width: 80px;
  background: #981d1f;
  height: 3px;
  margin-bottom: 31px !important;
}
.fa-info-circle {
  color: #891a1c;
  font-size: 35px;
  position: absolute;
  right: 60px;
  cursor: pointer;
  top: 45px;
}
.modal-header {
  padding: 7px 1rem;
  color: #ffff;
  background: #891a1c;
}
.modal .close {
  width: auto;
  opacity: inherit;
}
/* level form*/
.go50031923 {
  border-bottom: 4px solid #891a1c !important;
}
.go50031923:before {
  background-color: #891a1c !important;
}
.go2731971035:hover,
.go2731971035::before {
  color: #891a1c !important;
}
.go2348636356 {
  border-bottom: 4px solid #891a1c !important;
}
.go2348636356:before {
  color: white !important;
}
.go50031923:before {
  color: white !important;
}
.go1863277536 {
  text-align: center;
}
fieldset.scheduler-border {
  border: 1px groove #ced4da !important;
  padding: 0 1.4em 1.4em 1.4em !important; 
  margin: 0 0 1.5em 0 !important;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  color: #981d1f;
}
.kyclevelbox .submit-btn:hover,
.kyclevelbox button:hover {
  background-color: #3c3c3c;
  color: #ffffff;
}

/* You can add global styles to this file, and also import other style files */

/*Space css class
-----------------------------------*/

/*----------------
margin space
-----------------*/

.mb0 {
  margin-bottom: 0px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb100 {
  margin-bottom: 100px;
}
.mt0 {
  margin-top: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt60 {
  margin-top: 60px;
}
.mt80 {
  margin-top: 80px;
}
.mt100 {
  margin-top: 100px;
}
.mr0 {
  margin-right: 0px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 10px;
}
.ml0 {
  margin-left: 0px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}

/*----------------
less margin space from top
-----------------*/
.mt-20 {
  margin-top: -20px;
}
.mt-40 {
  margin-top: -40px;
}
.mt-60 {
  margin-top: -60px;
}
.mt-80 {
  margin-top: -80px;
}

/*---------------
Padding space
-----------------*/
.nopadding {
  padding: 0px;
}
.nopr {
  padding-right: 0px;
}
.nopl {
  padding-left: 0px;
}
.pinside10 {
  padding: 10px;
}
.pinside20 {
  padding: 20px;
}
.pinside30 {
  padding: 30px;
}
.pinside40 {
  padding: 40px;
}
.pinside50 {
  padding: 50px;
}
.pinside60 {
  padding: 60px;
}
.pinside80 {
  padding: 80px;
}
.pinside100 {
  padding: 100px;
}
.pdt10 {
  padding-top: 10px;
}
.pdt20 {
  padding-top: 20px;
}
.pdt30 {
  padding-top: 30px;
}
.pdt40 {
  padding-top: 40px;
}
.pdt60 {
  padding-top: 60px;
}
.pdt80 {
  padding-top: 80px;
}
.pdb10 {
  padding-bottom: 10px;
}
.pdb20 {
  padding-bottom: 20px;
}
.pdb30 {
  padding-bottom: 30px;
}
.pdb40 {
  padding-bottom: 40px;
}
.pdb60 {
  padding-bottom: 60px;
}
.pdb80 {
  padding-bottom: 80px;
}
.pdl10 {
  padding-left: 10px;
}
.pdl20 {
  padding-left: 20px;
}
.pdl30 {
  padding-left: 30px;
}
.pdl40 {
  padding-left: 40px;
}
.pdl60 {
  padding-left: 60px;
}
.pdl80 {
  padding-left: 80px;
}
.pdl120 {
  padding-left: 120px;
}
.pdr10 {
  padding-right: 10px;
}
.pdr20 {
  padding-right: 20px;
}
.pdr30 {
  padding-right: 30px;
}
.pdr40 {
  padding-right: 40px;
}
.pdr60 {
  padding-right: 60px;
}
.pdr80 {
  padding-right: 80px;
}
* {
  padding: 0;
  margin: 0;
}

ul.kyctext .fa-ora {
  color: #ffc107;
}

ul.kyctext {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 14px;
}
.kyctextlist {
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}
.kyctextlist1 {
  margin: 0 0 0 20px;
  padding: 0;
}
select {
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.connect-btn {
  width: 100% !important;
}

.social-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-menu li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 6px;
}

.social-menu li a i {
  width: 31px;
  height: 30px;
  background-color: #fff0;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  color: #fdfdfd;
  font-size: 14px;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.3s;
  border: 1px solid #ffffff61;
}

.social-menu li a:hover i {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.comtable  td, .comtable th{
 text-align: center;
 }
.comtable th {
  background-color: #bbbbbb;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  background-color: #bbbbbb;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    vertical-align: top !important;

}
.blankpage{
min-height:550px;
}

.hash-text{
  font-size: 9px;
}
.modal {
  padding-right: 0 !important;
}
.kycLevel li, .kycStep li {
    list-style-type: none;
}
.text-center-com {
  text-align: center !important;
  display: inline-grid;
  margin: 0 auto;
}

.customradio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  border:1px solid #BEBEBE;
}

/* On mouse-over, add a grey background color */
.customradio:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.customradio input:checked ~ .checkmark {
  background-color: white;
  border:1px solid #BEBEBE;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.customradio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #A3A0FB;
}

/* Custom Radio Button End*/

.level3 .border-style-img {
  display: inline-flex !important;
  justify-content: center;
}
.react-tel-input .selected-flag {
  z-index: 13;
  position: relative;
  width: 18px!important;
  height: 34px!important;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
  position: absolute !important;
  top: -2px;
  min-width: 63px!important;
  border-radius: 0 !important;
}
.react-tel-input {
  width: 60px!important;
}
.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: #000 !important;
}



.form {
  width: 400px;
}

.file-upload-wrapper {
  position: relative ;
  width:117%;
  height: 60px;
  border: 1px solid #ced4da;
  margin-bottom: 15px;
}
.file-upload-wrapper::before {
  content: 'Choose';
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  height: 35px;
  background: #981c1f;
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 16px;
  line-height: 30px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0;
  width:120px;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}
.file-upload-wrapper::after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 10px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: 40px;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
}
.border-style-img-fullwidth {
  border-style: dashed;
  width: 100%;
  height: 200px;
  color: #a7a7a7;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}