.outer{
  position: relative;
  width: 100vw;
  height: 60vh;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}