.error{
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
.hidden {
  visibility: hidden;
}
.kycdapp-plus-Img{
  /* width: 100px; */
  height: 100px;
  margin-top: 40px;
}