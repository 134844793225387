.m4-level{
 color:#9b2426;
 font-weight: bold;
 font-size: 20px;
}

.m4-txt-level{
 color: #230554;
 font-size: 18px;
}

label{
    font-weight: bold;
}

.note-para{
    font-size: 16px;
}

.flex-choose{
    display: flex;
    /*justify-content: left;*/
}

.kyclevelbox .submit-btn, .kyclevelbox button{
    border: 0px;
    min-width: 100px;
    height: 40px;
    background-color: #9b2426;
    color: #ffffff;
    border-radius: 10px;
    margin-right: 15px;
}
.kyclevelbox .submit-btn:hover, .kyclevelbox button:hover{
    background-color: #3c3c3c;
    color: #ffffff;
}

.submit-btn-flex{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.border-style-img{
    border-style: dashed;
    width: 200px;
    height: 200px;
    color: #a7a7a7;
    display: flex;
    justify-content: center;
    margin-left: 10px;

}

.display-border-style{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* .kycdapp-plus-Img{
    width: 100px;
    height: 100px;
    margin-top: 40px;
} */
textarea {
height:90px;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.es-trasnferbox .wallet-address{
    border-bottom: 5px solid #891a1c;
    font-size: 21px !important;
    color: #000;
    opacity: inherit;
    outline: none;
    font-weight: 600;
    word-wrap: break-word;

}
.es-trasnferbox .tot-ammount{
    font-size: 22px !important;
}
.es-trasnferbox  .yourwallet{
    padding: 10px;

    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
}
.es-trasnferbox  h5 {
    color: #891a1c;
}
.es-trasnferbox  h6{
    font-size: 1rem;
    color: #8a8a8a;
    font-weight: 400 !important;
    letter-spacing: inherit;
    text-align: justify;
}
/*level 2 css*/
.jm-logo {
    padding:20px 0 ;
    background: #f5f5f5;
    text-align: center;
        margin-bottom: 10px;
}
.jm-logo  .Img {
    height: 100px !important;
    padding: 10px;
    max-width: 100%;
}
.jm-logo a {
    position: relative;
    display: inline-block;
    height: 150px;
}
.jm-logo a:before {
    pointer-events: none;
    position: absolute;
    content: '';
    bottom: -11px;
    left: 5%;
    height: 10px;
    width: 90%;
    filter: alpha(opacity=0);
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: -webkit-radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: -moz-radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: -o-radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    -o-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -o-transition-property: transform, opacity;
    -ms-transition-property: transform, opacity;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
}
.jm-logo a:hover:before,
.jm-logo a:focus:before,
.jm-logo a:active:before {
    filter: alpha(opacity=100);
    opacity: 1;
}
.jm-logo span {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.jm-logo a:hover span,
.jm-logo a:focus span,
.jm-logo a:active span {
    -o-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.kycrejected{
    background: #f5f5f5;
    padding: 15px;
}
.kycrejected h3{
    color: #ff171b;
}
.kycrejected h3 .fa{
    margin-right: 15px;
    color: #ff171b;
    font-size: 35px;
}
.kycapprove{
    background: #f5f5f5;
    padding: 15px;
}
.kycapprove h3{
    color: #008e2c;
}
.kycapprove h3 .fa{
    margin-right: 15px;
    color: #008e2c;
    font-size: 35px;
}
.form-control.textHt{
    height: 95px;
}
.kyclevel4 textarea {
    height: 400px;
}
.submit-btn1{
  float: left;
}
.profile-btn{
  width: 100%;
}
.kyclevelbox button.close {
  border: 0px;
  min-width: 50px;
  height: 10px;
  background-color: #9b2426;
  color: #ffffff;
  border-radius: 10px;
  margin-right: 15px;
  position: absolute;
  right: -5px;
  top: 30px;
}
.kyclevelbox button.close span{
  position: absolute;
  top: 4px;
  right: 16px;
}
.col-border{
  border: 1px solid #eee;
}