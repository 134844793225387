.red-color-txt{
color: #9b2426;
}
.future-head{
    text-align: center;
    font-weight: bold;
    padding-top: 30px;
}
.para-txt-future{
    text-align: center;
    font-size: 20px;
}
.future-container{
    padding:40px 0 0 0;
}
.para-txt-future{
    color: #7C7D8D;
}
.feature-Img {
    width: 100px;
    height: 100px;
}
.feature-centr{
    display: flex;
    justify-content: center;
}
.kyc-feature-circle {
    margin-top: 20px;
    position: relative;
    top: 50px;
}
.feature-head{
    text-align: center;
    margin-top: 20px;
}
.kyc-section-wrapper {
    margin: 0 auto;
    padding-top: 8.5rem !important;
/*    padding-bottom: 8.5rem !important;*/
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
}

.v-shape-img-style{
    width: 100%;
    height: 600px;
    position: relative;
    margin-bottom: 0;
    bottom: 345px;
    margin-bottom: -275px;
    z-index: 1;
    /* width: 100%;
    height: 500px;
    position: relative;
    margin-bottom: 0;
    bottom: 225px;
    margin-bottom: -155px; */
}

p {
    font-weight: 400;
    font-family: "SFUI", sans-serif;
}

@media (max-width: 768px) {
    .kycdapp-Img{
        height: 90px;
        width: 120px !important;
    }
    .kyc-hero-bgd {
        background-color: #230554;
        height: 105vh !important;
    }
    .kyc-hero-img {
      width: 90% !important;
    }
    .future-head{
      padding-top: 20px;
    }
    .v-shape-img-style {
      width: 100% !important;
      height: 280px!important;
      bottom: 195px!important;
      margin-bottom: 5px!important;
      margin-bottom: -145px!important;
    }
}

.svg-shape--top {
    position: absolute !important;
    top: -2px;
    right: auto;
    bottom: auto;
    left: 0px;
    z-index: 1;
}
.fill-primary-light--1 rect, .fill-primary-light--1 path, .fill-primary-light--1 polygon, .fill-primary-light--1 circle {
    fill: #F5F9FF;
}
.dash-red{
    width: 80px;
    background: #981d1f;
    height: 3px;
    margin-bottom: 31px !important;
}

@media (max-width: 1024px) {

  .v-shape-img-style{
    width: 100%;
    height: 480px;
    position: relative;
    margin-bottom: 0;
    bottom: 265px;
    margin-bottom: -255px;
    z-index: 1;
  }
  }